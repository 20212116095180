import React from "react";

import Logo from "./Logo"

function MainContent() {
    return (
        <main>
            <Logo/>
            <p>Welcome to shopigent.com</p>
        </main>
    )
}

export default MainContent 