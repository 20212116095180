import React from "react";

function Logo() {
    return (
        <div className="logo">
            <img src="images/logo/logo.svg"/>
        </div>
    )
}

export default Logo